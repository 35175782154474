<script setup lang="ts">
const emit = defineEmits<{
  "update:modelValue": [value: number];
  click: [page: number];
}>();

const props = withDefaults(
  defineProps<{
    totalItems?: number;
    itemsPerPage?: number;
    modelValue?: number;
    showEndingButtons?: boolean;
    maxPagesShown?: number;

    dir?: "ltr" | "rtl";

    type?: "link" | "button";
    linkUrl?: string; // with [page] placeholder

    locale?: "en" | "ar" | "ir";

    prevButtonContent?: string;
    nextButtonContent?: string;

    hidePrevNext?: boolean;
    hidePrevNextWhenEnds?: boolean;

    disablePagination?: boolean;
    showBreakpointButtons?: boolean;

    // component props
    container?: boolean;
    mobileContainer?: boolean;
  }>(),
  {
    totalItems: 0,
    itemsPerPage: 10,
    modelValue: 1,
    showEndingButtons: true,
    maxPagesShown: 5,

    dir: "ltr",

    type: "link",
    linkUrl: undefined,

    locale: "en",

    prevButtonContent: "Poprzednia strona",
    nextButtonContent: "Następna strona",

    hidePrevNext: false,
    hidePrevNextWhenEnds: false,

    disablePagination: false,
    showBreakpointButtons: true,
  }
);

const model = useVModel(props, "modelValue", emit, {
  passive: true,
  defaultValue: 1,
});
</script>

<template>
  <div class="app-pagination">
    <div
      class="post-pagination__container"
      :class="[
        container ? 'container' : mobileContainer ? 'max-lg:container' : '',
      ]"
    >
      <VueAwesomePaginate
        v-bind="{
          totalItems,
          itemsPerPage,
          modelValue,
          showEndingButtons,
          maxPagesShown,

          dir,

          type,
          linkUrl,

          locale,

          prevButtonContent,
          nextButtonContent,

          hidePrevNext,
          hidePrevNextWhenEnds,

          disablePagination,
          showBreakpointButtons,
        }"
        v-model="model"
        @click="emit('click', $event)"
        type="link"
        pagination-container-class="w-full flex justify-center items-center flex-wrap"
        paginate-buttons-class="block min-w-[2.375rem] h-[2.375rem] border-[0.0625rem] rounded-full border-transparent px-2 select-none font-medium text-base text-center"
        active-page-class="!border-black"
        starting-breakpoint-button-class="text-xs"
        ending-breakpoint-button-class="text-xs"
      >
        <template #prev-button>
          <span :title="prevButtonContent" class="px-1">
            <Icon name="ns:chevron-left" class="w-6 h-6" />
          </span>
        </template>

        <template #starting-breakpoint-button>
          <span>•••</span>
        </template>

        <template #ending-breakpoint-button>
          <span>•••</span>
        </template>

        <template #next-button>
          <span :title="nextButtonContent" class="px-2">
            <Icon name="ns:chevron-right" class="w-6 h-6" />
          </span>
        </template>
      </VueAwesomePaginate>
    </div>
  </div>
</template>
